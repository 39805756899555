/** Dependencies */
import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import axios from 'axios';

/** Images */
import headerBorder from './assets/svg/top-border-2.svg';
import headerBackground from './assets/svg/header-background.svg';
import logo from './assets/png/logo.png';

/** Wallet */
import { wallet } from './wallet';

/** Styles */
import styles from './App.module.scss';
import './App.css';
import ButtonGroup from './components/ButtonGroup/ButtonGroup';

function App() {
  const { connectWallet } = wallet();

  const [walletId, setWalletId] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [browserId, setBrowserId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [playerName, setPlayerName] = useState('');
  const [shown, setShown] = useState(false);
  const [paramsURL, setParamsURL] = useState('');

  const setWalletToLocalStorage = ({
    address,
    starkPublicKey,
    uniqueIdUser,
  }) => {
    localStorage.setItem('address', JSON.stringify(address));
    localStorage.setItem('starkPublicKey', JSON.stringify(starkPublicKey));
    localStorage.setItem('uniqueIdUser', JSON.stringify(uniqueIdUser));
  };

  const handleConnectWalletClick = async () => {
    const walletInfo = await connectWallet();
    if (walletInfo) {
      setWalletToLocalStorage({
        address: JSON.stringify(walletInfo?.address),
        starkPublicKey: JSON.stringify(walletInfo?.starkPublicKey),
        uniqueIdUser: JSON.stringify(walletInfo?.uniqueIdUser),
      });

      const getTokenUser = await axios.post(
        `${process.env.REACT_APP_API_URL}/walletlogin`,
        {
          walletId: walletInfo?.address,
          uniqueId: walletInfo?.uniqueIdUser,
          browserId: browserId,
        }
      );

      if (getTokenUser?.data?.User) {
        setWalletId(walletInfo?.address);
        setUniqueId(walletInfo?.uniqueIdUser);
        setUserToken(getTokenUser?.data?.User);
      } else {
        alert('userToken not set. Problem user');
      }
    }
  };

  const handlePlayAsGuestClick = () => {
    setWalletId('');
    setUniqueId('');
    setShown(true);
  };

  const generateBrowserFingerprint = () => {
    let canvas = document.getElementById('myCanvas');
    let ctx = canvas.getContext('2d');

    ctx.fillStyle = 'rgb(255,0,255)';
    ctx.beginPath();
    ctx.rect(20, 20, 150, 100);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.beginPath();
    ctx.fillStyle = 'rgb(0,255,255)';
    ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();

    const txt = 'abz190#$%^@£éú';
    ctx.textBaseline = 'top';
    ctx.font = '17px "Arial 17"';
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = 'rgb(255,5,5)';
    ctx.rotate(0.03);
    ctx.fillText(txt, 4, 17);
    ctx.fillStyle = 'rgb(155,255,5)';
    ctx.shadowBlur = 8;
    ctx.shadowColor = 'red';
    ctx.fillRect(20, 12, 100, 5);

    // hashing function
    const src = canvas.toDataURL();
    let hash = 0;

    for (let i = 0; i < src.length; i++) {
      let char = src.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    // output this however you want
    return hash;
  };

  const handlePlayerNameOnChange = (e) => {
    setPlayerName(e?.target?.value);
  };

  const setUrlParams = useCallback(() => {
    setBrowserId(generateBrowserFingerprint());
    let urlParameters = `userToken=${userToken}`;
    setParamsURL(urlParameters);
  }, []);

  useEffect(() => {
    const urlParameters = `userToken=${userToken}`;
    setParamsURL(urlParameters);
    if (userToken) {
      setShown(true);
    }
  }, [userToken]);

  useEffect(() => {
    setBrowserId(generateBrowserFingerprint());
  }, [setUrlParams]);

  useEffect(() => {
    localStorage.setItem('game-playerName', playerName);

    if (document.getElementById('playerName') !== null)
      document.getElementById('playerName').innerHTML = playerName;
  }, [playerName]);

  useEffect(() => {
    localStorage.setItem('browserId', browserId);

    if (document.getElementById('browserId') !== null)
      document.getElementById('browserId').innerHTML = browserId;
  }, [browserId]);

  return (
    <>
      <canvas hidden id='myCanvas' width='200' height='40'></canvas>
      <div className={styles.header}>
        <img
          className={styles.headerBorder}
          src={headerBorder}
          alt='header border'
        />
        <img
          className={styles.headerBackground}
          src={headerBackground}
          alt='header background'
        />
        <a
          className={cx(styles.logo, {
            [styles.logoDisappear]: shown,
          })}
          href='https://planetquest.io'
          target={'_blank'}
          rel={'noreferrer'}
        >
          <img src={logo} alt='planetQuest logo' />
        </a>
      </div>
      <div
        className={cx(styles.App, {
          [styles.iframeShown]: shown,
          [styles.hasBeenADay]: true,
        })}
      >
        <>
          <ButtonGroup
            leftButtonText={'PLAY AS GUEST'}
            rightButtonText={'CONNECT WALLET'}
            handleLeftButtonClick={handlePlayAsGuestClick}
            handleRightButtonClick={handleConnectWalletClick}
            shown={!shown}
            showSubText
            scaled
            inModal={false}
          />

          <div className={styles.playerName}>
            <input
              onChange={handlePlayerNameOnChange}
              value={playerName}
              placeholder='Player Name'
              className={styles.playerNameInput}
              type='text'
            />
          </div>
        </>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '3vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            color: 'lightgrey',
            fontFamily: 'Orbitron',
            fontSize: '0.65vw',
          }}
        >
          Work in progress - Does not represent the final look of the game
        </p>
      </div>
      {/* <div id={'playerName'} style={{ display: 'none' }}></div> */}
      {/* <div id={'browserId'} style={{ display: 'none' }}></div> */}
      <iframe
        title={'pq'}
        id={'pq-frame'}
        className={cx(styles.iframe, { [styles.shown]: shown })}
        allow={
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        }
        src={shown ? `${process.env.REACT_APP_DEMO_URL}?${paramsURL}` : ''}
      />
      {/* )} */}
    </>
  );
}

export default App;
